.services_back_pic {
    width: 100%;
    height: 100%;
}

.services_cont {
    padding-top: 102px;
    position: relative;
}

.services_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1720px;
}

.services_content_b1 {
    max-width: 780px;
    width: 100%;
}

.services_content_b1 div {
    line-height: 150%;
}

.services_content_b1 div:first-child {
    margin-top: 18px;
}

.services_content_b1 div:last-child {
    margin-top: 28px;
}

.services_content_b2 {
    max-width: 700px;
    width: 100%;
    margin-left: 10%;
}

.services_content_b2_1, .services_content_b2_2 {
    max-width: 340px;
    width: 100%;
    padding: 40px;
    background: #FFF;
    border-radius: 10px;
    position: relative;
}

.services_content_b2_1 {
    box-shadow: 20px 22px 24px 0px #503524;
}

.services_content_b2_2 {
    background: #FFF;
    box-shadow: 20px 22px 24px 0px #80C463;
}


.services_content_b2_1 .price, .services_content_b2_2 .price {
    margin-bottom: 20px;
}

.brown_pic {
    position: absolute;
    top: 13%;
    z-index: -1;
    max-width: 340px;
    width: 100%;
}

.services_content_b2_1 ul, .services_content_b2_2 ul {
    list-style: none;
    border-top: 1px solid #ECECEC;
    padding-top: 20px;
}

.services_content_b2_1 ul li, .services_content_b2_2 ul li {
    margin-bottom: 17px;
    font-size: 15px;
    color: #848199;
    line-height: normal;
}

.services_content_b2_1 ul li:last-child, .services_content_b2_2 ul li:last-child {
    margin-bottom: 0px;
}

.services_content_b2_2 {
    margin-left: 80px;
    position: relative;
}

.choose_plan {
    position: absolute;
    bottom: -13%;
    left: 20%;
}