.Login_cont {
    padding-top: 102px;
}

.Login_cont_b1 {
    max-width: 1097px;
    width: 100%;
}

.login_pic {
    width: 100%;
    height: 100%;
}

.Login_cont_b2 {
    margin-left: 5%;
    margin-right: 15%;
    max-width: 556px;
    width: 100%;
}

.login_cont_b2 .title2 {
    margin-top: 10px;
}

.inputs_b {
    margin-top: 59px;
    margin-bottom: 24px;
    max-width: 556px;
    width: 100%;
}

.inputs_b .input_b {
    max-width: 556px;
    width: 100%;
    position: relative;
}

.inputs_b .input_b input {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #C8C7C7;
    background: #FFF;
    padding: 18px 26px 18px 56px;
}
.inputs_b .input_b input:focus {
    border: 1px solid rgba(128, 196, 99, 1);
}

.inputs_b .input_b img {
    position: absolute;
    top: 30%;
    left: 3%;
}
.forgot_pass, .have_account{
    cursor: pointer;
}
.have_account{
    margin-right: 16px;
}
.forgot_pass:hover{
   font-weight: 700;
}
.have_account span:hover{
    font-weight: 700;
}
.login_btn_b{
    max-width: 272px;
    width: 100%;
    border-radius: 10px;
}
.login_btn_b button{
    padding: 22px;
    width: 100%;
    border-radius: 10px;
}
.login_btn_b button:hover{
    transform: scale(1.05);
}