.home_cont{
    padding-top: 102px;
    position: relative;
}

.home_back{
    width: 100%;
    height: 100%;
}
.home_content{
    background: rgba(1, 1, 1, 0.30);
    max-width: 823px;
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
}
.home_content_inner{
    max-width: 850px;
    width: 100%;
    padding: 40% 38px  10%;
}
.home_content_inner p{
    max-width: 523px;
    width: 100%;
    line-height: 150% !important;
    margin-top: 98px;
}
.get_started_btn_b{
    margin-top: 43px;
    max-width: 272px;
    width: 100%;
    border-radius: 10px;
}
.get_started_btn_b button{
    padding: 22px;
    width: 100%;
    border-radius: 10px;
}
.get_started_btn_b button:hover{
  transform: scale(1.05);
}