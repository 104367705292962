.home_page_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-58%);
}

.home_page_content {
    max-width: 1256px;
    width: 100%;
}
.home_page_content1{
    max-width: 805px;
    width: 100%;
    margin: auto;
}

.home_page_content_blocks {
    margin-top: 75px;
    width: 100%;
    grid-column-gap: 46px;
    grid-row-gap: 28px;
}

.home_page_content_block {
    width: 595px;
    border-radius: 20px;
    background: #FFF;
    padding: 24px 37px;
}
.home_page_content_block_active {
    width: 100%;
    border-radius: 20px;
    background: #80C463;
    padding: 24px 37px;
}

.home_page_content_block span {
    margin-bottom: 12px;
}