.home_page2_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-58%);
}

.home_page_content_p {
    max-width: 800px;
    width: 100%;
}

.home_page_content_span {
    margin: 51px auto;
}

.pet_btns_b {
    max-width: 864px;
    width: 100%;
    margin: auto;
}

.pet_btn {
    max-width: 420px;
    width: 100%;
    border-radius: 20px;
    background: #FFF;
    padding: 18px;
}

.pet_btn_active {
    max-width: 420px;
    width: 100%;
    border-radius: 20px;
    background: #80C463;
    padding: 18px;
}

.pet_btn:first-child {
    margin-right: 24px;
}
.pet_btn_active:first-child {
    margin-right: 24px;
}


.home_page_content_input_b {
    max-width: 868px;
    width: 100%;
    margin: 42px auto 52px;
}

.home_page_content_input_b input {
    width: 100%;
    padding: 20px 41px;
    border-radius: 20px;
    background: #FFF;
}
.home_page_content_input_b input::placeholder {
    color: #B0AFAF;
}
.home_page_content_input_b input:focus {
  border: 1px solid #80C463;
}
.continue_btn_b{
    margin: auto;
    max-width: 273px;
    width: 100%;
   padding: 22px 85px;
    border-radius: 10px;
    background: #80C463;
}
.continue_btn_b:hover{
   transform: scale(1.02);
}